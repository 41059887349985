import { Button, Input, Label, useId } from "@fluentui/react-components";
import * as React from "react";

type SearchBarProps = {
  onSearch: (query: string) => Promise<void>;
};

export function SearchBar(props: SearchBarProps) {
  const { onSearch } = props;
  const searchId = useId("search");
  const [query, setQuery] = React.useState<string>("");
  const [isLoading, setLoading] = React.useState<boolean>(false);

  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    try {
      await onSearch(query);
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleOnSubmit} className="flex flex-row gap-2 my-2 items-end">
      <div className="grow flex flex-col gap-1">
        <Label htmlFor={searchId} hidden={true}>
          Search for anything
        </Label>
        <Input
          id={searchId}
          placeholder="How tall is Mount Everest?"
          onChange={(_, data) => {
            setQuery(data.value);
          }}
        />
      </div>
      <Button appearance="primary" type="submit" disabled={isLoading}>
        {isLoading ? "Searching…" : "Search"}
      </Button>
    </form>
  );
}
