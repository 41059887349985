import { Button } from "@fluentui/react-components";
import * as React from "react";
import { remarkProcessor } from "../../typography/MarkdownTransformer";

/* global globalThis */

type CopyButtonProps = {
  disabled: boolean;
  textToCopy: string;
  onClick?: () => any;
};

export default function MarkdownCopyButton(props: CopyButtonProps) {
  const { disabled, textToCopy, onClick } = props;

  async function copyResponseToClipboard() {
    const processedMarkdown = await remarkProcessor.process(textToCopy);
    const htmlOutput = processedMarkdown.toString();
    const type = "text/html";
    const blob = new Blob([htmlOutput], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await globalThis.navigator.clipboard.write(data);
    onClick && onClick();
  }

  return (
    <Button disabled={disabled} onClick={copyResponseToClipboard}>
      Copy
    </Button>
  );
}
