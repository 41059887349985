import "./taskpane.css";
import App from "./panes/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import AiService from "../services/AiService";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import SearchService from "../services/SearchService";

/* global document, Office, module, require, self, window, global, console */

function getGlobal() {
  return typeof self !== "undefined"
    ? self
    : typeof window !== "undefined"
    ? window
    : typeof global !== "undefined"
    ? global
    : undefined;
}
const g = getGlobal();

initializeIcons();

let isOfficeInitialized = false;

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider>
        <FluentProvider theme={webLightTheme}>
          <Component isOfficeInitialized={isOfficeInitialized} />
        </FluentProvider>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

async function updateTransformToolsVisibility(isEnabled: boolean) {
  return Office.ribbon
    .requestUpdate({
      tabs: [
        {
          id: "Draum.Tab",
          groups: [
            {
              id: "Draum.TransformTools",
              controls: [
                {
                  id: "Draum.TransformBullets",
                  enabled: isEnabled,
                },
                {
                  id: "Draum.TransformParagraph",
                  enabled: isEnabled,
                },
                {
                  id: "Draum.Rephrase",
                  enabled: isEnabled,
                },
                {
                  id: "Draum.Hyperbole",
                  enabled: isEnabled,
                },
                {
                  id: "Draum.Irony",
                  enabled: isEnabled,
                },
                {
                  id: "Draum.Understatement",
                  enabled: isEnabled,
                },
              ],
            },
            {
              id: "Draum.Write",
              controls: [
                {
                  id: "Draum.Expand",
                  enabled: isEnabled,
                },
              ],
            },
          ],
        },
      ],
    })
    .catch(console.error);
}

/* Render application after Office initializes */
Office.onReady(async () => {
  isOfficeInitialized = true;
  render(App);

  Office.context.document.addHandlerAsync(
    Office.EventType.DocumentSelectionChanged,
    async (event: Office.DocumentSelectionChangedEventArgs) => {
      event.document.getSelectedDataAsync(Office.CoercionType.Text, async (result: Office.AsyncResult<Text>) => {
        const text = result.value;
        await updateTransformToolsVisibility(!!text);
      });
    }
  );
});

export type Services = {
  aiService: AiService;
  searchService: SearchService;
};

if (g) {
  const aiService = new AiService();
  const searchService = new SearchService();

  g.services = {
    aiService,
    searchService,
  };
}

if ((module as any).hot) {
  (module as any).hot.accept("./panes/App", () => {
    const NextApp = require("./panes/App").default;
    render(NextApp);
  });
}
