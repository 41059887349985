import { Spinner } from "@fluentui/react-components";
import * as React from "react";

export interface ProgressProps {
  message: string;
}

export default class Progress extends React.Component<ProgressProps> {
  render() {
    const { message } = this.props;

    return (
      <section className="ms-welcome__progress ms-u-fadeIn500">
        <Spinner labelPosition="below" appearance="primary" label={message} />
      </section>
    );
  }
}
