/* eslint-disable no-constant-condition */
import * as React from "react";
import Progress from "../Progress";
import { CommandBar } from "@fluentui/react";
import AiService from "../../../services/AiService";
import { ReadType } from "../../../services/AiService/ReadTypes";
import { TransformType } from "../../../services/AiService/TransformTypes";
import MarkdownTransformer from "../../typography/MarkdownTransformer";
import { RouteProps } from "../../../constants";

/* global Word, globalThis */

export interface ReadProps extends RouteProps {}

// Create react component
export default function Read({ eventSourceId }: ReadProps) {
  const [response, setResponse] = React.useState<string>("");
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [readType, setReadType] = React.useState<ReadType | null>(null);

  const { aiService }: { aiService: AiService } = globalThis.services;

  React.useEffect(() => {
    switch (eventSourceId) {
      case "Draum.Summary":
        setReadType(ReadType.SUMMARY);
        break;
      case "Draum.Outline":
        setReadType(ReadType.OUTLINE);
        break;
      default:
        return;
    }
  }, [eventSourceId]);

  React.useEffect(() => {
    if (!readType) {
      return;
    }
    readText();
  }, [readType]);

  const readText = async () => {
    if (!readType) {
      return;
    }
    setLoading(true);
    return Word.run(async (context) => {
      const body = context.document.body;

      body.load("text");

      await context.sync();

      const text = body.text;

      await aiService.readText(readType, text, setResponse);

      setLoading(false);
    });
  };

  function getHeadline(): string {
    switch (readType) {
      case ReadType.SUMMARY:
        return "Summary";
      default:
        return "Read";
    }
  }

  return (
    <div className="flex flex-col gap-2 items-center px-2">
      <h1>{getHeadline()}</h1>
      <CommandBar
        items={[
          {
            key: "refresh",
            text: "Try again",
            iconProps: { iconName: "Refresh" },
            onClick: () => {
              setResponse("");
              readText();
            },
          },
          {
            key: "Transform",
            text: "Transform",
            iconProps: { iconName: "EditCreate" },
            subMenuProps: {
              items: [
                {
                  key: "Bullets",
                  text: "Bullets",
                  iconProps: { iconName: "BulletedList" },
                  onClick: () => {
                    aiService.transformText(TransformType.BULLETS, response, setResponse);
                  },
                },
                {
                  key: "Paragraphs",
                  text: "Paragraphs",
                  iconProps: { iconName: "TextDocument" },
                  onClick: () => {
                    aiService.transformText(TransformType.PARAGRAPH, response, setResponse);
                  },
                },
              ],
            },
          },
        ]}
        ariaLabel="Try again"
      />
      {isLoading && <Progress message="Reading document, please wait…" />}
      {!!response && <MarkdownTransformer className="w-full" markdown={response} />}
    </div>
  );
}
