/* eslint-disable no-constant-condition */
/* global fetch */

import { InvalidRequestError } from "../../constants";

declare const API_SERVER_BASE_URL: string;

export type SearchResult = {
  id: string;
  title: string;
  url: string;
  content: string;
  relevance: number;
};

export default class SearchService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = API_SERVER_BASE_URL;
  }

  async search(query: string): Promise<SearchResult[] | InvalidRequestError> {
    const response = await fetch(`${this.baseUrl}/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    });

    if (!response.ok || !response.body) {
      return new InvalidRequestError("Invalid request");
    }

    const {
      results,
    }: {
      results: SearchResult[];
    } = await response.json();

    return results;
  }
}
