export enum AppRoute {
  READ = "Read",
  HOME = "Home",
  REVIEW = "Review",
  WRITE = "Write",
  EXPAND = "Expand",
}

export type RouteDetails = {
  route: AppRoute;
  eventSourceId?: string;
};

export enum CustomEventNames {
  ROUTE_CHANGE = "RouteChangeEvent",
  RIBBON_BUTTON_CLICK = "RibbonButtonClickEvent",
}

export function routeChangeEventFactory(route: AppRoute, eventSourceId?: string) {
  return new CustomEvent<RouteDetails>(CustomEventNames.ROUTE_CHANGE, { detail: { route, eventSourceId } });
}

export type ClickEventDetails = {
  eventSourceId: string;
};

export function RibbonButtonClickEventFactory(eventSourceId) {
  return new CustomEvent<ClickEventDetails>(CustomEventNames.RIBBON_BUTTON_CLICK, { detail: { eventSourceId } });
}

export class InvalidRequestError extends Error {
  public message: string;
  constructor(message: string) {
    super("InvalidRequestError");
    this.message = message;
  }
}

export interface RouteProps {
  eventSourceId?: string;
}
