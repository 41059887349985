/* eslint-disable no-constant-condition */
import * as React from "react";
import Progress from "../Progress";
import { CommandBar } from "@fluentui/react";
import AiService from "../../../services/AiService";
import { ReviewType } from "../../../services/AiService/ReviewTypes";
import MarkdownTransformer from "../../typography/MarkdownTransformer";
import { RouteProps } from "../../../constants";

/* global Word, globalThis */

export interface ReviewProps extends RouteProps {}

// Create react component
export default function Review({ eventSourceId }: ReviewProps) {
  const [responseText, setResponseText] = React.useState<string>("");
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [reviewType, setReviewType] = React.useState<ReviewType | null>(null);

  const { aiService }: { aiService: AiService } = globalThis.services;

  React.useEffect(() => {
    switch (eventSourceId) {
      case "Draum.ExclusiveLanguage":
        setReviewType(ReviewType.EXCLUSIVE_LANGUAGE);
        break;
      case "Draum.AggressiveLanguage":
        setReviewType(ReviewType.AGGRESSIVE_LANGUAGE);
        break;
      case "Draum.SensitiveTopics":
        setReviewType(ReviewType.SENSITIVE_TOPICS);
        break;
      case "Draum.Profanity":
        setReviewType(ReviewType.PROFANITY);
        break;
      default:
        return;
    }
  }, [eventSourceId]);

  React.useEffect(() => {
    if (!reviewType) {
      return;
    }
    getReview();
  }, [reviewType]);

  const getReview = async () => {
    if (!reviewType) {
      return;
    }
    setLoading(true);
    return Word.run(async (context) => {
      const body = context.document.body;

      body.load("text");

      await context.sync();

      const text = body.text;

      await aiService.getReviewForText(reviewType, text, setResponseText);

      setLoading(false);
    });
  };

  return (
    <div className="flex flex-col gap-2 items-center px-2">
      <h1>Review</h1>
      <CommandBar
        items={[
          {
            key: "refresh",
            text: "Try again",
            iconProps: { iconName: "Refresh" },
            onClick: () => {
              setResponseText("");
              getReview();
            },
          },
        ]}
        ariaLabel="Commands"
      />
      {isLoading && <Progress message="Reviewing text, please wait…" />}
      {!!responseText && <MarkdownTransformer className="w-full" markdown={responseText} />}
    </div>
  );
}
