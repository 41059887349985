import * as React from "react";
import { Body1, Caption1, Card, CardHeader, Checkbox } from "@fluentui/react-components";
import { SearchResult } from "../../../services/SearchService";

export interface SearchResultsProps {
  results: SearchResult[];
  defaultSelectedResults?: SearchResult[];
  onSelectionUpdated: (selection: SearchResult[]) => void;
}

export default function SearchResults(props: SearchResultsProps) {
  const { results, defaultSelectedResults, onSelectionUpdated } = props;

  const [selectedResults, setSelectedResults] = React.useState<SearchResult[]>(defaultSelectedResults ?? []);

  React.useEffect(() => {
    onSelectionUpdated(selectedResults);
  }, [selectedResults]);

  function isSelected(result: SearchResult) {
    return !!selectedResults.find((selectedResult) => selectedResult.id === result.id);
  }

  return (
    <div role="list" className="flex flex-col gap-2">
      {results.map((result) => (
        <Card
          role="listitem"
          key={result.id}
          selected={isSelected(result)}
          onSelectionChange={(_, { selected }) => {
            if (selected) {
              setSelectedResults([...selectedResults, result]);
            } else {
              setSelectedResults(selectedResults.filter((selectedResult) => selectedResult.id !== result.id));
            }
          }}
          floatingAction={
            <Checkbox
              checked={isSelected(result)}
              onChange={(_, { checked }) => {
                if (checked) {
                  setSelectedResults([...selectedResults, result]);
                } else {
                  setSelectedResults(selectedResults.filter((selectedResult) => selectedResult.id !== result.id));
                }
              }}
            />
          }
        >
          <CardHeader header={<Body1>{result.title}</Body1>} description={<Caption1>{result.content}</Caption1>} />
        </Card>
      ))}
    </div>
  );
}
