import * as React from "react";
import { remark } from "remark";
import html from "remark-html";

export interface MarkdownTransformerProps {
  markdown: string;
  className?: string;
}

export const remarkProcessor = remark().use(html);

export default function MarkdownTransformer(props: MarkdownTransformerProps) {
  const { markdown } = props;

  const [htmlOutput, setHtmlOutput] = React.useState<string>("");

  React.useEffect(() => {
    const processMarkdown = async () => {
      const processedMarkdown = await remarkProcessor.process(markdown);
      setHtmlOutput(processedMarkdown.toString());
    };

    processMarkdown();
  }, [markdown]);

  return <div className={`prose ${props.className}`} dangerouslySetInnerHTML={{ __html: htmlOutput }} />;
}
