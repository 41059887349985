import { Button, CounterBadge, ToggleButton } from "@fluentui/react-components";
import * as React from "react";
import Progress from "../Progress";
import SearchResults from "./SearchResults";
import { SearchBar } from "./SearchBar";
import { SearchResult } from "../../../services/SearchService";
import { Services } from "../..";
import { ClickEventDetails, CustomEventNames, InvalidRequestError } from "../../../constants";
import { KnowledgeContext } from "../../../services/AiService/WriteTypes";
import { ExpandedResponse } from "./ExpandedResponse";

/* global globalThis, Word */
export interface ExpandProps {
  eventSourceId?: string;
}

export default function Expand(props: ExpandProps) {
  const { eventSourceId } = props;
  const { searchService, aiService } = globalThis.services as Services;

  const [text, setText] = React.useState<string>("");
  const [isResponseComplete, setResponseComplete] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<string>("");
  const [results, setResults] = React.useState<SearchResult[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [selectedResults, setSelectedResults] = React.useState<SearchResult[]>([]);
  const [showSelectedResults, setShowSelectedResults] = React.useState<boolean>(false);

  React.useEffect(() => {
    globalThis.addEventListener(CustomEventNames.RIBBON_BUTTON_CLICK, onRibbonButtonClick);
    return () => {
      globalThis.removeEventListener(CustomEventNames.RIBBON_BUTTON_CLICK, onRibbonButtonClick);
    };
  }, []);

  React.useEffect(() => {
    if (!eventSourceId) {
      return;
    }
    setLoading(true);
    switch (eventSourceId) {
      case "Draum.Expand":
        searchForTextSelection();
        break;
      default:
        return;
    }
  }, [eventSourceId]);

  async function onRibbonButtonClick(event: CustomEvent<ClickEventDetails>) {
    const { eventSourceId } = event.detail;
    switch (eventSourceId) {
      case "Draum.Expand":
        setSelectedResults([]);
        searchForTextSelection();
        break;
      default:
        return;
    }
  }

  async function searchForTextSelection() {
    return Word.run(async (context) => {
      const range = context.document.getSelection();

      range.load("text");
      await context.sync();

      setText(range.text);

      await search(range.text);
    });
  }

  async function search(query: string) {
    setLoading(true);
    setResults([]);
    try {
      const results = await searchService.search(query);
      if (results instanceof InvalidRequestError) {
        throw results;
      }
      setResults(results);
    } finally {
      setLoading(false);
    }
  }

  async function onExpand() {
    const expandContext: KnowledgeContext[] = selectedResults.map<KnowledgeContext>((result) => ({
      title: result.title,
      url: result.url,
      content: result.content,
    }));
    await aiService.expandText(text, expandContext, (text, done) => {
      setResponseComplete(done);
      setResponse(text);
    });
  }

  function renderSearchUi() {
    return (
      <>
        <SearchBar onSearch={search} />
        <div className="flex flex-row justify-start">
          <Button appearance="primary" onClick={onExpand} disabled={selectedResults.length === 0}>
            Expand
          </Button>
          <div className="grow" />
          <ToggleButton
            appearance="secondary"
            onClick={() => setShowSelectedResults(!showSelectedResults)}
            disabled={selectedResults.length === 0}
          >
            <CounterBadge className="mr-1" count={selectedResults.length} overflowCount={9} />
            <span>{showSelectedResults ? "Viewing selected" : "View selected"}</span>
          </ToggleButton>
        </div>
        {isLoading && <Progress message="Loading results…" />}
        {!response && (
          <SearchResults
            onSelectionUpdated={setSelectedResults}
            results={showSelectedResults ? selectedResults : results}
            defaultSelectedResults={selectedResults}
          />
        )}
      </>
    );
  }

  return (
    <div className="flex flex-col px-2 gap-2">
      {!response && renderSearchUi()}
      {response && <ExpandedResponse onCancel={() => setResponse("")} response={response} done={isResponseComplete} />}
    </div>
  );
}
