import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import Read from "./Read";
import Progress from "./Progress";
import { AppRoute, CustomEventNames, RouteDetails } from "../../constants";
import Review from "./Review";
import Write from "./Write";
import Expand from "./Expand";

/* global Word, self, window, global */

export interface AppProps {
  isOfficeInitialized: boolean;
}

function getGlobal() {
  return typeof self !== "undefined"
    ? self
    : typeof window !== "undefined"
    ? window
    : typeof global !== "undefined"
    ? global
    : undefined;
}

const g = getGlobal() as any;

// Create react component
export default function App({ isOfficeInitialized }: AppProps) {
  const [appRoute, setAppRoute] = React.useState<string>(AppRoute.HOME);
  const [eventSourceId, setEventSourceId] = React.useState<string | undefined>();

  React.useEffect(() => {
    (g as Window).addEventListener(CustomEventNames.ROUTE_CHANGE, onRouteChange);
    return () => {
      (g as Window).removeEventListener(CustomEventNames.ROUTE_CHANGE, onRouteChange);
    };
  }, []);

  const onRouteChange = async (event: CustomEvent<RouteDetails>) => {
    setAppRoute(event.detail.route);
    setEventSourceId(event.detail.eventSourceId);
  };

  const click = async () => {
    return Word.run(async (context) => {
      /**
       * Insert your Word code here
       */

      // insert a paragraph at the end of the document.
      const paragraph = context.document.body.insertParagraph("Hello World", Word.InsertLocation.end);

      // change the paragraph color to blue.
      paragraph.font.color = "blue";

      await context.sync();
    });
  };

  if (!isOfficeInitialized) {
    return <Progress message="Please sideload your addin to see app body." />;
  }

  switch (appRoute) {
    case AppRoute.READ:
      return <Read eventSourceId={eventSourceId} />;
    case AppRoute.REVIEW:
      return <Review eventSourceId={eventSourceId} />;
    case AppRoute.WRITE:
      return <Write eventSourceId={eventSourceId} />;
    case AppRoute.EXPAND:
      return <Expand eventSourceId={eventSourceId} />;
    default:
      return (
        <div className="ms-welcome">
          <p className="ms-font-l">
            Modify the source files, then click <b>Run</b>.
          </p>
          <p>Action: {appRoute}</p>
          <DefaultButton className="ms-welcome__action" iconProps={{ iconName: "ChevronRight" }} onClick={click}>
            Run
          </DefaultButton>
        </div>
      );
  }
}
