import { Button } from "@fluentui/react-components";
import * as React from "react";
import MarkdownTransformer from "../../typography/MarkdownTransformer";
import MarkdownCopyButton from "../../components/CopyButton";
import { ArrowLeftRegular } from "@fluentui/react-icons";

type ExpandedResponseProps = {
  onCancel: () => any;
  response: string;
  done: boolean;
};

export function ExpandedResponse(props: ExpandedResponseProps) {
  const { onCancel, response, done } = props;

  return (
    <>
      <div className="flex flex-row justify-start gap-2 my-2">
        <Button appearance="subtle" onClick={onCancel} icon={<ArrowLeftRegular />}>
          Back
        </Button>
        <MarkdownCopyButton disabled={!done} textToCopy={response} />
      </div>
      <MarkdownTransformer className="w-full" markdown={response} />
    </>
  );
}
