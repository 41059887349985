/* eslint-disable no-constant-condition */
import * as React from "react";
import Progress from "../Progress";
import { CommandBar, CommandBarButton, Dropdown, ResponsiveMode, TextField } from "@fluentui/react";
import AiService from "../../../services/AiService";
import { Instructions, WritingType } from "../../../services/AiService/WriteTypes";
import MarkdownTransformer, { remarkProcessor } from "../../typography/MarkdownTransformer";
import { RouteProps } from "../../../constants";
import { Button, ProgressBar } from "@fluentui/react-components";

/* global globalThis */

export interface WriteProps extends RouteProps {}

export enum Steps {
  Step1 = "Step1",
  Step2 = "Step2",
  Loading = "Loading",
  Response = "Response",
}

const documentTypes = [
  { key: "Article", text: "Article" },
  { key: "Essay", text: "Essay" },
  { key: "Letter", text: "Letter" },
  { key: "Report", text: "Report" },
  { key: "Story", text: "Story" },
];

const readingLevels = [
  { key: "Basic", text: "Basic" },
  { key: "Intermediate", text: "Intermediate" },
  { key: "Advanced", text: "Advanced" },
];

// Create react component
export default function Write({ eventSourceId }: WriteProps) {
  const [response, setResponse] = React.useState<string>("");
  const [step, setStep] = React.useState<Steps>(Steps.Step1);
  const [writingType, setWritingType] = React.useState<WritingType | null>(null);
  const [instructions, setInstructions] = React.useState<Instructions>({
    documentType: "article",
    topic: "",
  });
  const [isDone, setDone] = React.useState<boolean>(false);

  const { aiService }: { aiService: AiService } = globalThis.services;

  React.useEffect(() => {
    switch (eventSourceId) {
      case "Draum.Draft":
        setWritingType(WritingType.DOCUMENT);
        break;
      default:
        return;
    }
  }, [eventSourceId]);

  React.useEffect(() => {
    if (!writingType) {
      return;
    }
    //writeText();
  }, [writingType]);

  const writeText = async () => {
    if (!writingType) {
      return;
    }
    if (!instructions) {
      return;
    }
    setStep(Steps.Loading);

    await aiService.writeText(writingType, instructions, (text, done) => {
      setResponse(text);
      setDone(done);
    });

    setStep(Steps.Response);
  };

  async function copyResponseToClipboard() {
    const processedMarkdown = await remarkProcessor.process(response);
    const htmlOutput = processedMarkdown.toString();
    const type = "text/html";
    const blob = new Blob([htmlOutput], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await globalThis.navigator.clipboard.write(data);
  }

  function renderSteps() {
    switch (step) {
      case Steps.Loading:
        return <Progress message="Writing document, please wait…" />;
      case Steps.Step1:
        return (
          <>
            <h1>Create draft</h1>
            <form
              onSubmit={() => {
                setStep(Steps.Step2);
              }}
              className="flex flex-col gap-2"
            >
              <Dropdown
                label="What type of document do you want to write?"
                placeholder="Select an option"
                options={documentTypes}
                responsiveMode={ResponsiveMode.large}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    return;
                  }
                  setInstructions({ ...instructions, documentType: newValue.text });
                }}
                selectedKey={instructions.documentType}
                aria-required={true}
              />
              <TextField
                label="What do you want to write about?"
                multiline
                rows={4}
                autoAdjustHeight={true}
                placeholder="A narrative that delves into the lives of the merchants, explorers, and cities that thrived along the Silk Road."
                onChange={(_, newValue) => {
                  if (!newValue) {
                    return;
                  }
                  setInstructions({ ...instructions, topic: newValue });
                }}
                value={instructions.topic}
                aria-required={true}
              />
              <Dropdown
                label="What reading level do you want to write at?"
                placeholder="Select an option"
                options={readingLevels}
                responsiveMode={ResponsiveMode.large}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    return;
                  }
                  setInstructions({ ...instructions, readingLevel: newValue.text });
                }}
                selectedKey={instructions.readingLevel}
                aria-required={true}
              />
              <div className="flex flex-row">
                <Button appearance="primary" type="submit">
                  Next
                </Button>
              </div>
            </form>
          </>
        );
      case Steps.Step2:
        return (
          <>
            <h1>Create draft</h1>
            <div>
              <CommandBarButton
                text="Back"
                iconProps={{ iconName: "Back" }}
                onClick={() => {
                  setStep(Steps.Step1);
                }}
              />
            </div>
            <TextField
              label="Provide a rough outline of your document (optional)"
              multiline
              rows={20}
              onChange={(_, newValue) => {
                if (!newValue) {
                  return;
                }
                setInstructions({ ...instructions, outline: newValue });
              }}
              value={instructions.outline}
            />
            <div className="flex flex-row">
              <Button
                appearance="primary"
                onClick={() => {
                  writeText();
                }}
              >
                Create draft
              </Button>
            </div>
          </>
        );
      case Steps.Response:
        return (
          <>
            <h1>Draft</h1>
            <CommandBar
              items={[
                {
                  key: "refresh",
                  text: "Try again",
                  iconProps: { iconName: "Refresh" },
                  onClick: () => {
                    setResponse("");
                    setStep(Steps.Step1);
                  },
                },
                {
                  key: "copy",
                  text: "Copy",
                  iconProps: { iconName: "Copy" },
                  disabled: !isDone,
                  onClick: () => {
                    copyResponseToClipboard();
                  },
                },
              ]}
              styles={{ root: { padding: 0 } }}
            />
            {!isDone && <ProgressBar />}
            <MarkdownTransformer markdown={response} />
          </>
        );
      default:
        return <div></div>;
    }
  }

  return <div className="flex flex-col gap-2 px-2">{renderSteps()}</div>;
}
