/* eslint-disable no-constant-condition */
/* global fetch */

import { InvalidRequestError } from "../../constants";
import { ReadType } from "./ReadTypes";
import { ReviewType } from "./ReviewTypes";
import { TransformType } from "./TransformTypes";
import { KnowledgeContext, Instructions, WritingType } from "./WriteTypes";

declare const API_SERVER_BASE_URL: string;

export default class AiService {
  private baseUrl: string;
  constructor() {
    // Future space for FetchClient that handles adding Authorization headers, and supports proper streaming
    this.baseUrl = API_SERVER_BASE_URL;
  }

  private async _streamResponseText(response: Response, streamingUpdates: (text: string, done: boolean) => any) {
    if (!response.body) {
      return;
    }

    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    let streamingText = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        streamingUpdates(streamingText, done);
        break;
      }
      streamingText += value;
      streamingUpdates(streamingText, done);
    }
  }

  /**
   * Makes the LLM read text and produce a response, for example a summary or outline.
   *
   * @param readType The type of read to perform. See @ReadType
   * @param text The text to read
   * @param streamingUpdates An optional function that will be called with the response so far as it is being streamed
   * @returns A promise that resolves to a Response object containing the summary, or an InvalidRequestError if the request was invalid
   */
  async readText(
    readType: ReadType,
    text: string,
    streamingUpdates?: (text: string, done: boolean) => any
  ): Promise<Response | InvalidRequestError> {
    const response = await fetch(`${this.baseUrl}/read/${readType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text }),
    });

    if (!response.ok || !response.body) {
      return new InvalidRequestError("Invalid request");
    }

    if (streamingUpdates) {
      this._streamResponseText(response, streamingUpdates);
    }

    return response;
  }

  /**
   * Makes the LLM write text based on some instructions and a writing type.
   *
   * @param writingType The type of writing to perform. See @WritingType
   * @param instructions The instructions for the writing task. See @Instructions
   * @param streamingUpdates  An optional function that will be called with the response so far as it is being streamed
   * @returns
   */
  async writeText(
    writingType: WritingType,
    instructions: Instructions,
    streamingUpdates?: (text: string, done: boolean) => any
  ): Promise<Response | InvalidRequestError> {
    const response = await fetch(`${this.baseUrl}/write/${writingType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(instructions),
    });

    if (!response.ok || !response.body) {
      return new InvalidRequestError("Invalid request");
    }

    if (streamingUpdates) {
      this._streamResponseText(response, streamingUpdates);
    }

    return response;
  }

  async expandText(
    text: string,
    context: KnowledgeContext[],
    streamingUpdates?: (text: string, done: boolean) => any
  ): Promise<Response | InvalidRequestError> {
    const response = await fetch(`${this.baseUrl}/write/expand`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text, context }),
    });

    if (!response.ok || !response.body) {
      return new InvalidRequestError("Invalid request");
    }

    if (streamingUpdates) {
      this._streamResponseText(response, streamingUpdates);
    }

    return response;
  }

  async getReviewForText(
    reviewType: ReviewType,
    text: string,
    streamingUpdates?: (text: string, done: boolean) => any
  ): Promise<Response | InvalidRequestError> {
    const response = await fetch(`${this.baseUrl}/reviews/${reviewType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text }),
    });

    if (!response.ok || !response.body) {
      return new InvalidRequestError("Invalid request");
    }

    if (streamingUpdates) {
      this._streamResponseText(response, streamingUpdates);
    }

    return response;
  }

  /**
   * Transforms the given text using the given transform type.
   * @param transformType The type of transform to apply. See @TransformType
   * @param text The text to transform
   * @param streamingUpdates A function to call with the transformed text as it is being streamed
   * @returns A Response object containing the raw response from the service, or an InvalidRequestError if the request was invalid
   */
  async transformText(
    transformType: TransformType,
    text: string,
    streamingUpdates?: (text: string, done: boolean) => any
  ): Promise<Response | InvalidRequestError> {
    const response = await fetch(`${this.baseUrl}/transforms/${transformType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text }),
    });

    if (!response.ok || !response.body) {
      return new InvalidRequestError("Invalid request");
    }

    if (streamingUpdates) {
      this._streamResponseText(response, streamingUpdates);
    }

    return response;
  }
}
