export enum WritingType {
  DOCUMENT = "document",
}

export interface Instructions {
  topic: string;
  documentType: string;
  readingLevel?: string;
  outline?: string;
  context?: string;
}

export interface KnowledgeContext {
  title: string;
  content: string;
  url: string;
}
